import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import {
  addDocument,
  autoSaveVisit,
  createVisit,
  fetchDocuments,
  fetchTimerSNF,
  fetchTimerSOS,
  fetchUploadedPDF,
  fetchVisit,
  regenerateDocument,
  replaceDocument,
  savePDFSignDocument,
  searchDiagnosis,
  searchPatientByNameMRNOrDOB,
  searchVisitItem,
  transmitData,
  updateVisit,
  viewAndSignPDF,
  viewDocuments,
  viewedPDF,
  visitItemLocation
} from '../api/services/newVisit.service';
import { calculateAge, updateApiCodeLoadingInVisitItem } from '../utils/shared/common';
import { formatDate, transformItemsAndBundles } from '../utils/shared/formatHelper';

const initialState = {
  isPending: false,
  isPendingViewedPDF: false,
  isSuccess: false,
  isUpdateSuccess: false,
  isError: false,
  errorMessage: null,
  isSavePDFSuccess: false,
  isAddFormSuccess: false,
  isVisitWorkflowPending: false,
  activeStep: 0,
  completedSteps: [],
  selectedVisitSteps: [],
  // Select Patient
  totalRecords: 0,
  searchedPatientData: [],
  selectedPatientData: null,
  searchedVisitItemData: [],
  selectedItemData: null,
  addedVisitItemData: [],

  searchedDiagnosisData: [],
  documentList: null,
  addDocumentList: null,
  createdVisitData: null,
  fetchedVisitData: null,
  visitList: [],
  disableVisitMenu: false,
  visitItemLocationData: [],

  multiStepInfo: null,

  pdfPayload: null,
  fetchedPDF: null,
  isSignedPDF: false,
  uploadedPDF: null,
  isPatientBelow18: false,
  isOptInCommunication: null,
  viewedPDFDocument: null,

  recentSavedItem: null,
  remItemsToSave: [],
  itemFields: [],
  isReplace: false,
  fromSignDocument: false,
  fromDiscardRejected: false,
  isRegenerate: false,
  isVisitWorkflowCompleted: false,
  resetDocumentStatus: null,
  isTransmitDataPending: false,
  lastVisitItemAutoSaved: null,
  itemIdVisitItems: null,
  errorItems: [],

  pauseAutoSave: false,
  autoSaveFocusStatus: 'initial',
  stepperVisitFetch: false,
  isPendingTimerSOS: false,
  isPendingTimerSNF: false,
  timerSOSData: [],
  timerSNFData: [],
  itemHcpcsDetails: [],
  diagnosisMissingRequired: false,

  lateralityErrorItems: [],
  redCount: 0,
  showPayerMessage: false
};

const newVisitSlice = createSlice({
  name: 'newVisit',
  initialState,
  reducers: {
    setLateralityErrorItems: (state, action) => {
      return { ...state, lateralityErrorItems: action.payload };
    },
    clearVisitItemLocationData: (state) => {
      return { ...state, visitItemLocationData: [] };
    },
    setStepperVisitFetch: (state, action) => {
      return { ...state, stepperVisitFetch: action.payload };
    },
    setPauseAutoSave: (state, action) => {
      return { ...state, pauseAutoSave: action.payload };
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setCompletedSteps: (state, action) => {
      return { ...state, completedSteps: [...Array(action.payload + 1).keys()] };
    },
    setSearchedPatientData: (state, action) => {
      return { ...state, searchedPatientData: action.payload };
    },
    setSelectedPatientData: (state, action) => {
      return { ...state, selectedPatientData: action.payload };
    },
    setSearchedVisitItemData: (state, action) => {
      return { ...state, searchedVisitItemData: action.payload };
    },
    setSearchedDiagnosisData: (state, action) => {
      return { ...state, searchedDiagnosisData: action.payload };
    },
    setSelectedVisitSteps: (state, action) => {
      return { ...state, selectedVisitSteps: action.payload };
    },
    setDiagnosisMissingRequired: (state, action) => {
      return { ...state, diagnosisMissingRequired: action.payload };
    },
    setShowPayerMessage: (state, action) => {
      return { ...state, showPayerMessage: action.payload };
    },
    resetVisitData: (state, action) => {
      return {
        ...state,
        createdVisitData: null,
        fetchedVisitData: null,
        documentList: null,
        visitList: [],
        redCount: 0,
        // disableVisitMenu: false,
        multiStepInfo: null,
        itemHcpcsDetails: [],
        diagnosisMissingRequired: false,
        lateralityErrorItems: [],
        showPayerMessage: false,
        activeStep: action?.payload?.step ?? 0,
        completedSteps: [...Array(action?.payload?.completed ?? 0).keys()]
      };
    },
    setDisableVisitMenu: (state, action) => {
      return { ...state, disableVisitMenu: action.payload };
    },
    setMultiStepInfo: (state, action) => {
      return { ...state, multiStepInfo: action.payload };
    },
    setPDFPayload: (state, action) => {
      return { ...state, pdfPayload: action.payload };
    },
    setPatientBelow18: (state, action) => {
      state.isPatientBelow18 = action.payload;
    },
    setOptInCommunication: (state, action) => {
      state.isOptInCommunication = action.payload;
    },
    setRecentSavedItem: (state, action) => {
      return { ...state, recentSavedItem: action.payload };
    },
    setRemItemsSave: (state, action) => {
      return { ...state, remItemsToSave: action.payload };
    },
    setItemFields: (state, action) => {
      return { ...state, itemFields: action.payload };
    },
    resetVisitItemForm: (state) => {
      return { ...state, recentSavedItem: null, remItemsToSave: [], itemFields: [] };
    },
    setFromSignDocument: (state, action) => {
      return { ...state, fromSignDocument: action.payload };
    },
    setFromDiscardRejected: (state, action) => {
      return { ...state, fromDiscardRejected: action.payload };
    },
    setIsReplace: (state, action) => {
      return { ...state, isReplace: action.payload };
    },
    setErrorItems: (state, action) => {
      return { ...state, errorItems: action.payload };
    },
    setRedCount: (state, action) => {
      return { ...state, redCount: action.payload };
    },

    resetDocumentStatus: (state) => {
      return {
        ...state,
        isVisitWorkflowCompleted: false,
        isAddFormSuccess: false,
        isRegenerate: false,
        isReplace: false
      };
    },
    setLastVisitItemAutoSaved: (state, action) => {
      return {
        ...state,
        lastVisitItemAutoSaved: action.payload
      };
    },
    setItemIdVisitItems: (state, action) => {
      return {
        ...state,
        itemIdVisitItems: action.payload
      };
    },
    setAutoSaveFocus: (state, action) => {
      return { ...state, autoSaveFocusStatus: action.payload };
    },
    setTimerSNFData: (state, action) => {
      return { ...state, timerSNFData: action.payload };
    },
    setTimerSOSData: (state, action) => {
      return { ...state, timerSOSData: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(searchPatientByNameMRNOrDOB.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(searchPatientByNameMRNOrDOB.fulfilled, (state, action) => {
        let total = 0;
        let fetchedData = [];

        if (action?.payload) {
          const newData = action?.payload?.map((p) => {
            p.patientName = `${p.first_Name} ${p.last_Name}`;
            p.date_Of_Birth = formatDate(p.date_Of_Birth);
            p.age = calculateAge(p.date_Of_Birth);
            p.policy_number = p.master_Payer_Name ?? null;
            p.mrn = p.account_Id;
            p.id = p.patient_Id;
            return p;
          });
          total = action?.payload?.length ? action?.payload[0].totalNoRecord : 0;
          fetchedData = newData || [];
        }

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          totalRecords: total,
          searchedPatientData: fetchedData
        };
      })
      .addCase(searchPatientByNameMRNOrDOB.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(searchVisitItem.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(searchVisitItem.fulfilled, (state, action) => {
        const transformedArray = transformItemsAndBundles(action?.payload);

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          searchedVisitItemData: transformedArray
        };
      })
      .addCase(searchVisitItem.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(visitItemLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(visitItemLocation.fulfilled, (state, action) => {
        const visitItemLocationData = uniqBy(
          [action.payload, ...state.visitItemLocationData],
          'item_id'
        );

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          visitItemLocationData
        };
      })
      .addCase(visitItemLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(searchDiagnosis.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(searchDiagnosis.fulfilled, (state, action) => {
        const searchedDiagnosisData = action?.payload?.items;

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          searchedDiagnosisData
        };
      })
      .addCase(searchDiagnosis.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(createVisit.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isVisitWorkflowPending: true
        };
      })
      .addCase(createVisit.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isVisitWorkflowPending: false,
          createdVisitData: action.payload
        };
      })
      .addCase(createVisit.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isVisitWorkflowPending: false,
          errorMessage: action.error.message
        };
      })

      .addCase(autoSaveVisit.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isVisitWorkflowPending: true
        };
      })
      .addCase(autoSaveVisit.fulfilled, (state, action) => {
        const itemHcpcs = action?.payload?.itemHcpcs_Details;

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isVisitWorkflowPending: false,
          itemHcpcsDetails: itemHcpcs
        };
      })
      .addCase(autoSaveVisit.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isVisitWorkflowPending: false,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchVisit.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isFetchVisitPending: true
        };
      })
      .addCase(fetchVisit.fulfilled, (state, action) => {
        const list = action.payload || {};
        const visitData = updateApiCodeLoadingInVisitItem(list);

        return {
          ...state,
          isPending: false,
          isFetchVisitPending: false,
          isSuccess: true,
          isUpdateSuccess: false,
          fetchedVisitData: visitData,
          completedSteps: [...Array(list?.visit?.step + 1).keys()]
        };
      })
      .addCase(fetchVisit.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isFetchVisitPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(updateVisit.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isVisitWorkflowCompleted: false,
          isVisitWorkflowPending: true
        };
      })
      .addCase(updateVisit.fulfilled, (state, action) => {
        const index = state.visitList.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.visitList[index] = action.payload;
        }
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isVisitWorkflowCompleted: true,
          isVisitWorkflowPending: false,
          isUpdateSuccess: true
        };
      })
      .addCase(updateVisit.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isVisitWorkflowCompleted: false,
          isVisitWorkflowPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(viewAndSignPDF.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(viewAndSignPDF.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchedPDF: action.payload
        };
      })
      .addCase(viewAndSignPDF.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(viewedPDF.pending, (state) => {
        return {
          ...state,
          isPendingViewedPDF: true
        };
      })
      .addCase(viewedPDF.fulfilled, (state, action) => {
        return {
          ...state,
          isPendingViewedPDF: false,
          isSuccess: true,
          viewedPDFDocument: action.payload
        };
      })
      .addCase(viewedPDF.rejected, (state, action) => {
        return {
          ...state,
          isPendingViewedPDF: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchUploadedPDF.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchUploadedPDF.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isSignedPDF: action.payload[0]?.sign_Status,
          uploadedPDF: action.payload[0]
        };
      })
      .addCase(fetchUploadedPDF.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(savePDFSignDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isSavePDFSuccess: false
        };
      })
      .addCase(savePDFSignDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSignedPDF: true,
          isSavePDFSuccess: true
        };
      })
      .addCase(savePDFSignDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(viewDocuments.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(viewDocuments.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          documentList: action.payload
        };
      })
      .addCase(viewDocuments.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(replaceDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isReplace: false
        };
      })
      .addCase(replaceDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isReplace: true
        };
      })
      .addCase(replaceDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isReplace: false,
          errorMessage: action.error.message
        };
      })
      .addCase(regenerateDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isRegenerate: false
        };
      })
      .addCase(regenerateDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isRegenerate: true
        };
      })
      .addCase(regenerateDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          isRegenerate: false
        };
      })
      .addCase(fetchDocuments.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          addDocumentList: action.payload
        };
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(addDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isAddFormSuccess: false
        };
      })
      .addCase(addDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isAddFormSuccess: true
        };
      })
      .addCase(addDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isAddFormSuccess: false,
          errorMessage: action.error.message
        };
      })
      .addCase(transmitData.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isTransmitDataPending: true
        };
      })
      .addCase(transmitData.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isTransmitDataPending: false
        };
      })
      .addCase(transmitData.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isTransmitDataPending: false,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchTimerSOS.pending, (state) => {
        return {
          ...state,
          isPendingTimerSOS: true
        };
      })
      .addCase(fetchTimerSOS.fulfilled, (state, action) => {
        const stateTimerData = Array.isArray(state.timerSOSData) ? state.timerSOSData : [];
        const newData = action?.payload?.soSResponseEntity || [];
        newData.soSClaim = action?.payload?.soSClaim || [];

        const updatedTimerData = stateTimerData?.map((item) =>
          item.requestId === newData?.requestId ? newData : item
        );

        const isNewDataUnique = !stateTimerData.some(
          (item) => item.requestId === newData?.requestId
        );

        if (isNewDataUnique && newData) {
          updatedTimerData.push(newData);
        }

        const uniqTimerSOSData = uniqBy(updatedTimerData, 'requestId');

        return {
          ...state,
          isPendingTimerSOS: false,
          timerSOSData: uniqTimerSOSData
        };
      })
      .addCase(fetchTimerSOS.rejected, (state, action) => {
        return {
          ...state,
          isPendingTimerSOS: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchTimerSNF.pending, (state) => {
        return {
          ...state,
          isPendingTimerSNF: true
        };
      })

      .addCase(fetchTimerSNF.fulfilled, (state, action) => {
        const { details, ...rest } = action.payload;

        // Create new data object with details or an empty array
        const newData = {
          ...rest,
          snfResponseDetails: details || []
        };

        // Extract the single snfResponse
        const newSnfResponse = { snfResponses: [newData] };

        return {
          ...state,
          isPendingTimerSNF: false,
          timerSNFData: newSnfResponse
        };
      })

      .addCase(fetchTimerSNF.rejected, (state, action) => {
        return {
          ...state,
          isPendingTimerSNF: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = newVisitSlice;

export const {
  clearVisitItemLocationData,
  setStepperVisitFetch,
  setPauseAutoSave,
  setActiveStep,
  setCompletedSteps,
  setSearchedPatientData,
  setSelectedPatientData,
  setSearchedVisitItemData,
  setSearchedDiagnosisData,
  resetVisitData,
  setDisableVisitMenu,
  setMultiStepInfo,
  setPDFPayload,
  setPatientBelow18,
  setOptInCommunication,
  setRecentSavedItem,
  setRemItemsSave,
  setItemFields,
  resetVisitItemForm,
  setFromSignDocument,
  setFromDiscardRejected,
  resetDocumentStatus,
  setIsReplace,
  setLastVisitItemAutoSaved,
  setErrorItems,
  setAutoSaveFocus,
  setSelectedVisitSteps,
  setTimerSOSData,
  setTimerSNFData,
  setRedCount,
  setDiagnosisMissingRequired,
  setLateralityErrorItems,
  setItemIdVisitItems,
  setShowPayerMessage
} = actions;

export default reducer;
