import { createSlice } from '@reduxjs/toolkit';
import {
  AddItemsToStock,
  EditStockDetails,
  GetCountedDaysByLocation,
  GetSourceLocationDropDown,
  GetStocksByLocation,
  GetStocksDetails,
  UpdateStock,
  UpdateStockTransfer,
  getStockByFilter,
  getStockTiles
} from '../api/services/stock.service';

const initialState = {
  isPending: false,
  isLoadedTiles: true,
  isError: false,
  errorMessage: null,
  stockCountData: [],
  getAllStocks: null,
  stockListAPIPayload: {
    locationId: null,
    searchInput: '',
    statusId: null
  },
  stockList: [],
  stockStatusId: null,
  stocksByLocation: [],
  updateStockList: [],
  counted_Days: 0,
  getStockItemsList: [],
  updatedStockItemsList: [],
  getStocksDetailsList: [],
  getSourceLocationDropDownList: [],
  updateStockTransfer: false,
  updateStockTransferData: false,
  updateStockCount: false,
  editStockDetails: false,
  watchTileChanges: false,
  stockEditWatch: false,
  isQOHChange: false,
  stockCancelWatch: false,
  countedDaysByLocation: 0,
  getItemsByFilterMessage: '',
  isStockPending: false,
  cellDescModel: {
    isOpen: false,
    data: null
  }
};

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setCellDescModel: (state, action) => {
      return { ...state, cellDescModel: action.payload };
    },
    setStockData: (state, action) => {
      return { ...state, getAllStocks: action.payload };
    },
    setStockLists: (state, action) => {
      return { ...state, stockList: action.payload };
    },
    setStockStatusId: (state, action) => {
      return { ...state, stockStatusId: action.payload };
    },
    setStockListAPIPayload: (state, action) => {
      return { ...state, stockListAPIPayload: action.payload };
    },
    setUpdateStockByLocation: (state, action) => {
      return { ...state, stocksByLocation: action.payload };
    },
    setUpdatedStockItemsList: (state, action) => {
      return { ...state, getStockItemsList: action.payload };
    },
    setUpdateStockList: (state, action) => {
      return { ...state, updateStockList: action.payload };
    },
    setSourceLocationDropDown: (state, action) => {
      return { ...state, getSourceLocationDropDownList: action.payload };
    },
    setGetStocksDetailsList: (state, action) => {
      return { ...state, getStocksDetailsList: action.payload };
    },
    setStockEditWatch: (state, action) => {
      return { ...state, stockEditWatch: action.payload };
    },
    setIsQOHChange: (state, action) => {
      return { ...state, isQOHChange: action.payload };
    },
    setStockCancelWatch: (state, action) => {
      return { ...state, stockCancelWatch: action.payload };
    },
    setUpdateStock: (state, action) => {
      return { ...state, updateStockCount: action.payload };
    },
    setGetItemsByFilterMessage: (state, action) => {
      return { ...state, getItemsByFilterMessage: action.payload };
    },
    setUpdateStockTransferData: (state, action) => {
      return { ...state, updateStockTransferData: action.payload };
    },
    setWatchTileChanges: (state, action) => {
      return { ...state, watchTileChanges: action.payload };
    },
    setEditStockDetails: (state, action) => {
      return { ...state, editStockDetails: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getStockByFilter.pending, (state) => {
        return {
          ...state,
          isStockPending: true
        };
      })
      .addCase(getStockByFilter.fulfilled, (state, action) => {
        return {
          ...state,
          isStockPending: false,
          getAllStocks: action?.payload?.data
        };
      })
      .addCase(getStockByFilter.rejected, (state, action) => {
        return {
          ...state,
          isStockPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(getStockTiles.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true
        };
      })
      .addCase(getStockTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          stockCountData: action?.payload?.data
        };
      })
      .addCase(getStockTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetStocksByLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetStocksByLocation.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          stocksByLocation: action?.payload?.stocks,
          counted_Days: action?.payload?.counted_Days
        };
      })
      .addCase(GetStocksByLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(EditStockDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(EditStockDetails.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          editStockDetails: true
        };
      })
      .addCase(EditStockDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateStock.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdateStock.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(UpdateStock.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(AddItemsToStock.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddItemsToStock.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(AddItemsToStock.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateStockTransfer.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdateStockTransfer.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          updateStockTransfer: true
        };
      })
      .addCase(UpdateStockTransfer.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetStocksDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetStocksDetails.fulfilled, (state, action) => {
        const rData = [...state.getStocksDetailsList, ...action.payload];
        return {
          ...state,
          isPending: false,
          getStocksDetailsList: rData
        };
      })
      .addCase(GetStocksDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetSourceLocationDropDown.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetSourceLocationDropDown.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          getSourceLocationDropDownList: action?.payload
        };
      })
      .addCase(GetSourceLocationDropDown.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetCountedDaysByLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetCountedDaysByLocation.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          countedDaysByLocation: action?.payload
        };
      })
      .addCase(GetCountedDaysByLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = stockSlice;

export const {
  setStockData,
  setStockListAPIPayload,
  setStockLists,
  setStockStatusId,
  setUpdateStockList,
  setUpdateStockByLocation,
  setUpdatedStockItemsList,
  setSourceLocationDropDown,
  setGetStocksDetailsList,
  setStockEditWatch,
  setIsQOHChange,
  setStockCancelWatch,
  setUpdateStock,
  setCellDescModel,
  setGetItemsByFilterMessage,
  setUpdateStockTransferData,
  setWatchTileChanges,
  setEditStockDetails
} = actions;

export default reducer;
