import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Purchase/utils';
import apiClient from '../axios';

export const searchItemData = async (searchText, isFromStock = false) => {
  const { data } = await apiClient.get(
    `${ENDPOINTS.SEARCH_CART_ITEM}?searchText=${searchText}&Is_FromStock=${isFromStock}`
  );
  return data;
};

export const addItemToCart = createAsyncThunk(
  'purchase/addItemToCart',
  async (payloadData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_ITEM_TO_CART, payloadData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error?.response?.data?.errorMessage : '');
    }
  }
);

export const getGridItemDataByLocation = async ({ location_id, item_id }) => {
  const { data } = await apiClient.post(
    `${ENDPOINTS.CART_ITEM_lOCATION_STOCK}?item_id=${item_id}`,
    location_id
  );
  return data;
};

export const fetchCartLocation = createAsyncThunk('purchase/fetchCartLocation', async () => {
  const { data } = await apiClient.get(ENDPOINTS.CART_LOCATION);
  return data;
});

export const fetchPurchaseCount = createAsyncThunk('purchase/fetchPurchaseCount', async () => {
  const { data } = await apiClient.get(ENDPOINTS.PURCHASE_COUNT);
  return data;
});

export const fetchPurchaseList = createAsyncThunk(
  'purchase/fetchPurchaseList',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.PURCHASE_LIST, payloadData);
    return data;
  }
);

export const fetchPurchseOrderbyIDs = createAsyncThunk(
  'purchase/fetchPurchseOrderbyIDs',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.PURCHASE_ORDER_LIST, payloadData);
    return (
      data?._purchaseModel?.map((d) => {
        d.orderItem?.map((f) => {
          f.location_id = d.location_id;
          return f;
        });
        return d;
      }) || []
    );
  }
);

export const fetchPaymentMethod = createAsyncThunk('purchase/fetchPaymentMethod', async () => {
  const { data } = await apiClient.get(ENDPOINTS.PURCHASE_PAYMENT_METHOD);
  return data;
});

export const fetchShipMethod = createAsyncThunk('purchase/fetchShipMethod', async () => {
  const { data } = await apiClient.get(ENDPOINTS.SHIP_PAYMENT_METHOD);
  return data;
});

export const fetchPurchaseOrderDetail = createAsyncThunk(
  'purchase/fetchPurchaseOrderDetail',
  async (purchaseOrderId) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.PURCHASE_ORDER_DETAIL}?Purchase_Order_Id=${purchaseOrderId}`
    );
    return {
      ...data,
      purchaseOrderItemDetails: data?.purchaseOrderItemDetails?.map((d) => {
        d.location_id = data?.purchaseOrderDetail?.[0]?.location_id;
        return d;
      })
    };
  }
);

export const fetchPurchaseOrderTransactionLogs = createAsyncThunk(
  'purchase/fetchPurchaseOrderTransactionLogs',
  async (purchaseOrderId) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.RECEIVE_PO_TRANSACTIONI_LOGS}?purchaseOrderId=${purchaseOrderId}`
    );
    return data;
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  'purchase/updatePurchaseOrder',
  async (payloadData) => {
    try {
      const { data } = await apiClient.put(ENDPOINTS.PURCHASE_ORDER_UPDATE, payloadData);
      return data;
    } catch (error) {
      return error?.response ? error?.response?.data : error?.message;
    }
  }
);

export const updateReceivePOQuantity = createAsyncThunk(
  'purchase/updateReceivePOQuantity',
  async (payloadData) => {
    const { data } = await apiClient.put(ENDPOINTS.UPDATE_RECEIVE_PO_QUANTITY, payloadData);
    return data;
  }
);

export const updateCartQty = createAsyncThunk('purchase/updateCartQty', async (payloadData) => {
  const url = `${ENDPOINTS.CART_ITEM_UPDATE}?id=${payloadData?.id}&cart_qty=${payloadData?.cart_qty}`;
  const { data } = await apiClient.put(url);
  return data;
});

export const DraftPurchaseOrderDelete = createAsyncThunk(
  'purchase/DraftPurchaseOrderDelete',
  async (payloadData) => {
    const { data } = await apiClient.delete(ENDPOINTS.PURCHASE_ORDER_DELETE, { data: payloadData });
    return data;
  }
);

export const fetchPurchaseOrderStatus = createAsyncThunk(
  'purchase/fetchPurchaseOrderStatus',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.PURCHASE_ORDER_STATUS);
    return data;
  }
);

export const shoppingCartItemsDelete = createAsyncThunk(
  'purchase/shoppingCartItemsDelete',
  async (payloadData) => {
    const { data } = await apiClient.delete(ENDPOINTS.SHOPPING_CART_DELETE, { data: payloadData });
    return data;
  }
);

export const approveAndTransmit = createAsyncThunk(
  'purchase/approveAndTransmit',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.APPROVE_TRANSMIT, payloadData);
    return data;
  }
);

export const fetchItemListForPurchaseItem = createAsyncThunk(
  'purchase/fetchItemListForPurchaseItem',
  async (itemId) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.ITEM_LIST_FOR_PURCHASE_ITEM}?Item_Id=${itemId}`
    );
    return data;
  }
);

export const fetchItemListForPurchaseVendor = async (itemId) => {
  const { data } = await apiClient.get(
    `${ENDPOINTS.ITEM_LIST_FOR_PURCHASE_ITEM}?Item_Id=${itemId}`
  );
  return data;
};

export const fetchShoppingCartLastRunCronJobDetail = createAsyncThunk(
  'purchase/fetchShoppingCartLastRunCronJobDetail',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.SHOPPING_CART_LAST_RUN_CRON_JOB);
    return data;
  }
);

export const GetDownloadPO = createAsyncThunk(
  'settings/GetDownloadPO',
  async (purchaseOrderId, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${ENDPOINTS.DOWNLOAD_PO}?Purchase_Order_Number=${purchaseOrderId}`,
        {
          responseType: 'arraybuffer'
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
