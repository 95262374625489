import { get, isEmpty } from 'lodash';
import { setLateralityErrorItems } from '../../slices/newvisit.slice';
import {
  GREEN_COLOR,
  LOADER_API_RESPONSE_CODE,
  RED_COLOR,
  TENANT_ID
} from '../../utils/shared/constants';
import { formatDateDOS } from '../../utils/shared/formatHelper';

export const RENTAL_TEXT = 'Rental';
export const COMPLETED_VISIT_STATUS_ID = 'fede1321-8215-41fd-a505-11b0a27ad5ce';

export const buttonStyles = {
  button: {
    textTransform: 'none'
  }
};

export const fixedDropdownScroll = {
  disableScrollLock: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export const patientSortColumn = 'first_Name';
export const patientSortOrder = 'ASC';

export const buttonLabel = (step, statusBasedLabel = '') => {
  const stepLabels = {
    0: '',
    1: 'Go to Visit Details',
    2: 'Go to Estimated Responsibility',
    3: 'Go to Finalize Visit',
    4: statusBasedLabel
  };
  return get(stepLabels, step, '');
};

export const selectPatientPayload = ({ searchInput, pageSize, currentPage }) => {
  return {
    tenant_Id: TENANT_ID,
    globalSearch: searchInput,
    pagesize: pageSize,
    currentpage: currentPage
  };
};

export const adjustDescription = (description) => {
  if (description?.endsWith(' Left')) {
    return description.slice(0, -5);
  } else if (description?.endsWith(' Right')) {
    return description.slice(0, -6);
  }
  return description;
};

export const trimAfterLastPeriod = (text) => {
  const lastPeriodIndex = text?.lastIndexOf('.');
  return lastPeriodIndex > 0 ? text?.substring(0, lastPeriodIndex) : text;
};

export const removeLeadingZero = (input) => {
  if (input.startsWith('0') && !input.startsWith('0.') && input.length > 1) {
    return input.substring(1);
  }
  return input;
};

export const diagnosisOptionLabel = (diagnosisoption) => {
  return diagnosisoption.diagnosis_code && diagnosisoption.diagnosis_name
    ? `${diagnosisoption.diagnosis_code} - ${diagnosisoption.diagnosis_name}`
    : '';
};

export const extractLeftOrRight = (description) => {
  let side = '';

  if (description?.endsWith(' Left')) {
    side = 'Left';
  } else if (description?.endsWith(' Right')) {
    side = 'Right';
  }

  return side;
};

export const getSOSColor = (hcpcs, updatedData) => {
  const dataArray = Array.isArray(updatedData) ? updatedData : [];

  if (dataArray.length === 0) {
    return RED_COLOR;
  }

  const responsesForHcpcs = updatedData?.filter((response) => response?.hcpcs === hcpcs);
  if (responsesForHcpcs?.length === 0) {
    return null;
  }
  const hasValidData = responsesForHcpcs?.[0]?.apiResponseCode === 0;
  const isLoader = responsesForHcpcs?.[0]?.apiResponseCode === 3;

  if (!isLoader) {
    if (hasValidData) {
      const sosClaim = responsesForHcpcs?.[0]?.soSClaim;
      const matchedClaim = sosClaim?.find((claim) => claim?.hcpcs === hcpcs);

      if (matchedClaim?.sameCategoryBilled === 'PASS') {
        return GREEN_COLOR;
      }
      if (matchedClaim?.sameCategoryBilled === 'FAIL') {
        return RED_COLOR;
      }
    }
    return RED_COLOR;
  }
};

export const findRejectedId = (dataArray, value) => {
  if (!Array.isArray(dataArray)) {
    return null;
  }

  const foundItem = dataArray.find((item) => item.status_description === value);
  return foundItem ? foundItem?.id : null;
};

export const getBillTypeDescription = (billingType, item) => {
  const billTypeId = item?.billing_type_id;
  return billingType?.find((billTypes) => billTypes.id === billTypeId)?.description || '--';
};

export const isRentalSelected = (selectedBillingType, billingType) => {
  const selectedRental = billingType?.find((biilType) => biilType?.id === selectedBillingType);
  return selectedRental?.description === RENTAL_TEXT;
};

export const findProvideType = (providerType, providerId) => {
  return providerType?.find((provider) => provider.id === providerId);
};

const addDiagnosisFields = (formValue) =>
  formValue?.diagnosis?.id
    ? {
        diagonis_code_id: formValue.diagnosis.id,
        diagnosis_code: formValue.diagnosis.diagnosis_code,
        diagnosis_name: formValue.diagnosis.diagnosis_name
      }
    : {};

const addCustomFitDescription = (formValue) =>
  formValue?.is_customfit
    ? {
        custom_fit_description_details: formValue.custom_fit_description_details?.trim()
      }
    : {};

export const locationSelection = (userTenant, selectedLocation) => {
  const lastTenantId = userTenant?.userInformation?.last_Tenant_Id;
  const initialTenantId = userTenant?.tenantInformation?.find(
    (tenant) => tenant.tenant_Id === lastTenantId
  )
    ? lastTenantId
    : userTenant?.tenantInformation[0]?.tenant_Id;

  const selectedTenantData = userTenant?.tenantInformation?.find(
    (tenant) => tenant.tenant_Id === initialTenantId
  );

  const foundLocation = selectedTenantData?.tenantLocations.find(
    (location) => location?.location_Id === selectedLocation
  );

  let currentLocation = foundLocation?.location_Id || foundLocation?.default_Warehouse;

  return currentLocation;
};

export const displayUserLocation = (userTenant, selectedLocation, currentLocations) => {
  const lastTenantId = userTenant?.userInformation?.last_Tenant_Id;
  const initialTenantId = userTenant?.tenantInformation?.find(
    (tenant) => tenant.tenant_Id === lastTenantId
  )
    ? lastTenantId
    : userTenant?.tenantInformation[0]?.tenant_Id;

  const selectedTenantData = userTenant?.tenantInformation?.find(
    (tenant) => tenant.tenant_Id === initialTenantId
  );

  const foundLocation = selectedTenantData?.tenantLocations.find(
    (location) => location?.location_Id === selectedLocation
  );

  let currentLocation = currentLocations?.find(
    (location) => location.location_Id === foundLocation?.default_Warehouse
  );

  return currentLocation;
};

const calculateRentalDays = (isRental, formValue) => {
  return isRental ? parseInt(formValue?.rental_days || 1) : 0;
};

// Helper function to determine location ID
const determineLocationId = (formValue, selectedItem, defaultLocation) => {
  return (
    formValue?.location_id ||
    selectedItem?.location_id ||
    selectedItem?.bundle_items?.[0]?.location_id ||
    defaultLocation
  );
};

// Helper function to parse HCPCS codes if bundled
const parseHcpcsCodes = (isBundled, hcpcs) => {
  if (!isBundled || !hcpcs) return { itemhcpcscode: '', itemcustomhcpcs: '' };
  const [itemhcpcscode = '', itemcustomhcpcs = ''] = hcpcs.split('~');
  return { itemhcpcscode, itemcustomhcpcs };
};

const determineCustomFit = (formValue, itemhcpcscode, itemcustomhcpcs) => {
  if (formValue?.is_customfit) {
    return formValue.is_customfit;
  }

  if (!itemhcpcscode && itemcustomhcpcs) {
    return 1;
  }

  return 0;
};

const calculateIsCustomFit = (isBundled, formValue, itemhcpcscode, itemcustomhcpcs) => {
  if (isBundled) {
    return determineCustomFit(formValue, itemhcpcscode, itemcustomhcpcs);
  }
  return formValue?.is_customfit || (!formValue?.hcpcs && formValue?.customhcpcs) ? 1 : 0;
};

export const generateVisitItem = (
  formValue,
  isEdit,
  isRental,
  defaultLocation,
  selectedItem,
  isBundled = false
) => {
  const rental_days = calculateRentalDays(isRental, formValue);
  const { itemhcpcscode, itemcustomhcpcs } = parseHcpcsCodes(isBundled, formValue?.hcpcs);
  const locationId = determineLocationId(formValue, selectedItem, defaultLocation);
  const is_customfit = calculateIsCustomFit(isBundled, formValue, itemhcpcscode, itemcustomhcpcs);

  const diagnosisFields = addDiagnosisFields(formValue);
  const customFitDescription = addCustomFitDescription(formValue);

  const commonFields = {
    ...diagnosisFields,
    hcpcs: formValue?.hcpcs,
    vendor_part_number: formValue?.vendor_part_number,
    serial_number: '',
    quantity: parseInt(formValue?.quantity),
    price_charge: parseFloat(formValue?.price_charge) || 0,
    allowed_per_item: '10',
    source_primary_key: '',
    rental_days: rental_days,
    is_customfit: is_customfit,
    location_id: locationId,
    lot_serial_number: formValue?.lot_serial_number,
    total_allowed: formValue?.total_allowed,
    unit_allowed: formValue?.unit_allowed,
    additional_mod: formValue?.additional_mod,
    laterality: formValue?.laterality,
    is_prior_auth: formValue?.is_prior_auth,
    is_lot_number_required: formValue?.is_lot_number_required,
    prior_auth_status: formValue?.prior_auth_status,
    prior_auth_code: formValue?.prior_auth_status === 'received' ? formValue?.prior_auth_code : '',
    right_left_id: formValue?.right_left_id,
    ...(formValue?.billing_type_id !== '' && { billing_type_id: formValue?.billing_type_id }),
    ...customFitDescription
  };

  const newItem = {
    ...commonFields,
    item_id: formValue?.item_id,
    description: isEdit ? formValue.description : `${formValue.item_name} ${formValue.size}`
  };

  return isEdit
    ? { id: formValue?.visitFieldId, visit_id: formValue?.visit_id, ...newItem }
    : newItem;
};

export const deleteVisitItem = (formValue, isRentalSelected, itemHcpcs_Details) => {
  const hcpcsDetail = itemHcpcs_Details.find(
    (detail) => detail.visit_Item_Id === formValue.visitFieldId
  );
  const hcpcs = hcpcsDetail ? hcpcsDetail.hcpcs : formValue.hcpcs;

  const commonFields = {
    diagonis_code_id: formValue?.diagnosis?.id,
    diagnosis_code: formValue?.diagnosis?.diagnosis_code,
    diagnosis_name: formValue?.diagnosis?.diagnosis_name,
    hcpcs: hcpcs,
    vendor_part_number: formValue?.vendor_part_number,
    serial_number: '',
    quantity: parseInt(formValue?.quantity),
    price_charge: parseFloat(formValue?.price_charge) || 0,
    allowed_per_item: '10',
    source_primary_key: '',
    rental_days: isRentalSelected ? parseInt(formValue?.rental_days) : 0,
    is_customfit: formValue?.is_customfit ? 1 : 0,
    ...(formValue?.billing_type_id !== '' && {
      billing_type_id: formValue?.billing_type_id
    }),
    is_deleted: 1,
    location_id: formValue?.location_id
  };

  const newItem = {
    ...commonFields,
    item_id: formValue?.item_id,
    description: formValue?.description
  };

  return { id: formValue?.visitFieldId, visit_id: formValue?.visit_id, ...newItem };
};

export const formattedVisitData = (data) => {
  return {
    id: data?.id,
    visit_Status_Id: data?.visit_Status_Id,
    date_Of_Service: data?.Dateofservice,
    ordering_Provider_Id: data?.Orderingprovider?.id,
    rendering_Provider_Id: data?.Renderingprovider?.id,
    supervising_Provider_Id: data?.Supervisingprovider?.id,
    fitter: data?.Fitter?.id,
    visit_Comments: data?.addComments,
    step: data?.step,
    auth_Status_Id: data?.auth_Status_Id
  };
};

export const addItemsMultiStepInfo = ({
  multiStepInfo,
  fetchedVisitData,
  providerTypes,
  dispatch,
  setMultiStepInfo,
  visitStatus,
  selectedLocation
}) => {
  const {
    orderingProviderType,
    supervisingProviderType,
    renderingProviderType,
    fitterProviderType
  } = providerTypes || {};

  const fetchedVisitStatus = fetchedVisitData?.visit ?? {};
  const visitId = fetchedVisitStatus?.id || '';
  const visitAuthStatusId = fetchedVisitStatus?.auth_Status_Id || '';
  const DOS = fetchedVisitStatus?.date_Of_Service || '';
  const dateOfService = DOS !== '0001-01-01T00:00:00' ? formatDateDOS(DOS) : DOS;
  const visitComment = fetchedVisitStatus?.visit_Comments || '';
  const rejected_status_Id = findRejectedId(visitStatus, 'Patient Refused');
  const discard_status_Id = findRejectedId(visitStatus, 'Discard');

  const findProvider = (providerType, providerId) => {
    return providerType?.find((provider) => provider.id === providerId);
  };

  // Check and set multiStepInfo with patient_Id
  if (!multiStepInfo?.patient_Id && fetchedVisitData?.patient_Id) {
    const actionData = {
      tenant_Id: TENANT_ID,
      patient_Id: fetchedVisitData?.patient_Id,
      rejectedId: rejected_status_Id,
      discardId: discard_status_Id,
      location_Id: selectedLocation
    };
    dispatch(setMultiStepInfo({ ...multiStepInfo, ...actionData }));
  }

  // Check and set multiStepInfo with visit details
  if (
    !multiStepInfo?.visit &&
    fetchedVisitStatus?.ordering_Provider_Id &&
    fetchedVisitStatus?.ordering_Provider_Id !== '00000000-0000-0000-0000-000000000000'
  ) {
    const multiStepVisit = {
      id: visitId,
      auth_Status_Id: visitAuthStatusId,
      Dateofservice: dateOfService,
      Orderingprovider: findProvider(
        orderingProviderType,
        fetchedVisitStatus?.ordering_Provider_Id
      ),
      Renderingprovider: findProvider(
        renderingProviderType,
        fetchedVisitStatus?.rendering_Provider_Id
      ),
      Fitter: findProvider(fitterProviderType, fetchedVisitStatus?.fitter),
      Supervisingprovider: findProvider(
        supervisingProviderType,
        fetchedVisitStatus?.supervising_Provider_Id
      ),
      addComments: visitComment
    };
    dispatch(setMultiStepInfo({ ...multiStepInfo, visit: multiStepVisit }));
  }

  // Check and set paymentCollectedToday
  if (
    !multiStepInfo?.paymentCollectedToday &&
    fetchedVisitData?.visitSummary?.[0]?.payment_Collected_Today >= 0
  ) {
    const paymentCollectedToday = fetchedVisitData?.visitSummary?.[0]?.payment_Collected_Today;

    if (multiStepInfo?.paymentCollectedToday !== paymentCollectedToday) {
      dispatch(setMultiStepInfo({ ...multiStepInfo, paymentCollectedToday }));
    }
  }
};

export const parseResponseMessage = (message) => {
  let messageToDisplay = message?.trim();
  if (messageToDisplay?.includes('{')) {
    try {
      let endIndex = messageToDisplay.indexOf('}') + 1;
      let jsonString = messageToDisplay.substring(0, endIndex);
      let parsedData = JSON.parse(jsonString);
      messageToDisplay = parsedData.detailedmessage || parsedData.error || messageToDisplay;
    } catch (error) {
      console.error('Error parsing message JSON', error);
      // Optionally return the original message or handle the error as needed
    }
  }
  return messageToDisplay;
};

export const calculateEffectiveCompletedSteps = (
  selectedVisitSteps,
  completedSteps,
  fetchedItems
) => {
  if (selectedVisitSteps?.length > 0 && isEmpty(fetchedItems)) {
    return [0];
  } else {
    return (
      (selectedVisitSteps?.length > completedSteps?.length ? selectedVisitSteps : completedSteps) ||
      []
    );
  }
};

export const ENDPOINTS = {
  // Visit and New Visit
  VISIT_SEARCH_PATIENT: 'patientservice/api/patient/search',
  VISIT_SEARCH_ITEM: 'inventoryservice/api/inventory/GetSearchByItem',
  VISIT_ITEM_LOCATION: 'inventoryservice/api/Inventory/GetLocationTypes',
  VISIT_SEARCH_DIAGNOSIS: 'userservice/api/DiagonisCode/SearchDiagnosis',
  VISIT_CREATE_UPDATE: 'patientservice/api/Visit/visitworkflow',
  VISIT_FETCH: 'patientservice/api/Visit/visitworkflowdetails',
  VIEW_AND_SIGN_PDF: 'patientservice/api/DocumentUpload/ViewDocuments',
  VISIT_DOCUMENT_REPLACE: 'patientservice/api/DocumentUpload/ReplaceDocument',
  VISIT_DOCUMENT_REGENERATE: 'patientservice/api/DocumentUpload/Regenerate',
  FETCH_DOCUMENTS: 'patientservice/api/DocumentUpload/GetTenantDocuments',
  VISIT_ADD_DOCUMENTS: 'patientservice/api/DocumentUpload/AddTenantForms',
  VISIT_VIEW_DOCUMENTS: 'patientservice/api/DocumentUpload/ListOfDocuments',
  GET_MIGRATED_DOCUMENT: 'patientservice/api/Documents/GetMigratedDocument',
  GET_VIEW_DOWNLOAD_DOCUMENTS: 'patientservice/api/Documents/GetDocumentById',
  SAVE_PDF_SIGN_DOCUMENTS: 'patientservice/api/DocumentUpload/ViewAndSignDocument',
  VIEW_PDF: 'patientservice/api/DocumentUpload/viewed',
  FETCH_UPLOADED_PDF: `patientservice/api/DocumentUpload/GetUploadedFiles/?tenant_Id=${TENANT_ID}`,
  VISIT_TRANSMIT_DOCUMENTS: '/patientservice/api/Hl7/hl7outbound',
  VISIT_COUNT: 'patientservice/api/Visit/visitcountDetails',
  VISIT_LIST: 'patientservice/api/Visit/visits',
  GENERATE_CUSTOM_DESCRIPTION:
    'patientservice/api/VisitPromptResponse/GenerateCustomFitDescriptionDetails',
  TENANT_AI_KEYWORD: 'patientservice/api/VisitPromptResponse/GetTenantPromptKeywords',
  UPDATE_ITEM_TO_CART: 'inventoryservice/api/ShoppingCart/additemttoshoppingcart',
  TIMER_SOS: 'patientservice/api/PVerifyApi/getsameorsimilar',
  TIMER_SNF: 'patientservice/api/PVerifyApi/GetSNFResponse',
  ALL_VISIT_STATUS: 'UserService/api/VisitStatus/visitStatus'
};

export const isRequestIdPresent = (arr, requestId) =>
  arr?.some((item) => item?.requestId === requestId);

export const apiCallWithLoading = (data) => {
  return data?.filter((resp) => resp.apiResponseCode === LOADER_API_RESPONSE_CODE);
};

export const apiCallWithSNFLoading = (data) => {
  return data?.snfResponses?.filter((resp) =>
    typeof resp?.apiResponseCode !== 'undefined'
      ? resp?.apiResponseCode === LOADER_API_RESPONSE_CODE
      : resp?.api_Response_Code === LOADER_API_RESPONSE_CODE
  );
};
export const checkAndUpdateLateralityErrors = ({
  diagnosisName,
  option,
  itemVisitFieldId,
  lateralityErrorItems,
  dispatch,
  t,
  toastError,
  toastId
}) => {
  const hasError =
    (diagnosisName?.trim()?.toUpperCase()?.includes('RIGHT') && option?.toUpperCase() === 'LEFT') ||
    (diagnosisName?.trim()?.toUpperCase()?.includes('LEFT') && option?.toUpperCase() === 'RIGHT');

  const isItemInErrorList = lateralityErrorItems?.includes(itemVisitFieldId);

  if (hasError && !isItemInErrorList) {
    const updatedLateralityErrorArray = [...lateralityErrorItems, itemVisitFieldId];
    dispatch(setLateralityErrorItems(updatedLateralityErrorArray));
    toastError(t('toaster.DiagnosisLateralityError'), {
      toastId: toastId
    });
  } else if (hasError && isItemInErrorList) {
    toastError(t('toaster.DiagnosisLateralityError'), {
      toastId: toastId
    });
  } else if (!hasError && isItemInErrorList) {
    const updatedLateralityErrorArray = lateralityErrorItems?.filter(
      (id) => id !== itemVisitFieldId
    );
    dispatch(setLateralityErrorItems(updatedLateralityErrorArray));
  }
};

export const getExtension = (url) => {
  const parsedUrl = new URL(url);
  const fileName = parsedUrl?.pathname?.split('/')?.pop();

  return fileName?.includes('.') ? fileName?.split('.').pop().toLowerCase() : '';
};

export const getExtensionFromMimeType = (mimeType) => {
  const mimeTypeToExtension = {
    'application/pdf': 'pdf',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif'
  };

  return mimeTypeToExtension[mimeType] || '';
};
