import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import {
  DraftPurchaseOrderDelete,
  GetDownloadPO,
  addItemToCart,
  approveAndTransmit,
  fetchCartLocation,
  fetchItemListForPurchaseItem,
  fetchPaymentMethod,
  fetchPurchaseCount,
  fetchPurchaseList,
  fetchPurchaseOrderDetail,
  fetchPurchaseOrderStatus,
  fetchPurchaseOrderTransactionLogs,
  fetchPurchseOrderbyIDs,
  fetchShipMethod,
  fetchShoppingCartLastRunCronJobDetail,
  shoppingCartItemsDelete,
  updateCartQty,
  updatePurchaseOrder,
  updateReceivePOQuantity
} from '../api/services/purchase.service';
import { SHOPPING_CART_STATUS_ID } from '../utils/shared/constants';

const initialState = {
  isPending: false,
  isLoadedTiles: true,
  isPOPending: true,
  isPendingTransactionLogs: false,
  isVisitFetched: false,
  isPurchaseFetched: false,
  isCustomFitDataFetched: false,
  isPurchaseOrderFetched: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  isPurchaseCountFetched: false,
  purchaseListAPIPayload: {
    globalSearchValue: '',
    location_Id: null,
    pO_Status_Id: [SHOPPING_CART_STATUS_ID],
    IsFromDashboard: false
  },
  visitCountData: null,
  cutomFitDataByAI: null,
  visitList: [],
  purchaseList: [],
  vendorsList: [],
  paymentMethods: null,
  shipMethods: null,
  updatePurchaseOrderData: null,
  updateReceivePOData: null,
  updateShoppingCartQtyData: null,
  purchaseCountData: null,
  purchaseOrderDetail: null,
  purchaseOrdersData: null,
  deletePurchaseOrderData: null,
  isPurchaseOrderDeleted: false,
  purchaseOrderStatusData: [],
  shoppingCartItemsDelete: null,
  isShoppingCartItemDeletePending: false,
  isShoppingCartItemDeleted: false,
  viewPOByStatus: {
    poID: null,
    isApprovalView: false,
    isDraftView: false,
    isReceiveView: false,
    isRejectView: false,
    isCompletedView: false
  },
  receivePOData: {
    purchase_order_Id: null,
    item_id: null,
    qtyReceivedToday: 0
  },
  cartLocation: [],
  selectedLocation: [],
  selectedPOStatus: [],
  activeAccrodian: {
    aIndex: null,
    poId: null
  },
  purchaseOrderTransactionLogs: null,
  isPendingUpdateReceiveItems: false,
  isCreateView: false,
  lastRunCronDetails: '',
  isCronPending: false,
  fromPurchaseDashboard: false,
  downloadPOData: []
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchaseList: (state, action) => {
      return { ...state, purchaseList: action.payload };
    },

    setPurchaseListAPIPayload: (state, action) => {
      return { ...state, purchaseListAPIPayload: action.payload };
    },
    setViewPOByStatus: (state, action) => {
      return { ...state, viewPOByStatus: action.payload };
    },
    setIsCreateView: (state, action) => {
      return { ...state, isCreateView: action.payload };
    },
    setReceivePOData: (state, action) => {
      return { ...state, receivePOData: action.payload };
    },
    setPurchaseOrderDetail: (state, action) => {
      return { ...state, purchaseOrderDetail: action.payload };
    },
    setSelectedPurchaseLocation: (state, action) => {
      return { ...state, selectedPurchaseLocation: action.payload };
    },
    setSelectedPOStatus: (state, action) => {
      return { ...state, selectedPOStatus: action.payload };
    },
    setActiveAccrodian: (state, action) => {
      return { ...state, activeAccrodian: action.payload };
    },
    setIsPurchaseCountFetched: (state, action) => {
      return { ...state, isPurchaseCountFetched: action.payload };
    },
    setFromPurchaseDashboard: (state, action) => {
      return { ...state, fromPurchaseDashboard: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPurchaseCount.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true,
          isPurchaseCountFetched: false
        };
      })
      .addCase(fetchPurchaseCount.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isSuccess: true,
          isPOPending: false,
          isPurchaseCountFetched: true,
          purchaseCountData: action.payload
        };
      })
      .addCase(fetchPurchaseCount.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          isPOPending: false,
          errorMessage: action.error.message,
          isPurchaseCountFetched: false
        };
      })

      .addCase(fetchPurchaseList.pending, (state) => {
        return {
          ...state,
          isPurchaseFetched: true
        };
      })
      .addCase(fetchPurchaseList.fulfilled, (state, action) => {
        const updatedShoppingCart = action?.payload?.shoppingCardSearchResponse?.map((item) => {
          // Check if both patient_Name and visit_ID are present
          if (item.patient_Name && item.visit_ID) {
            return {
              ...item,
              // Only add combinedPatientVisitInfo if both values are present
              combinedPatientVisitInfo: `${item.patient_Name} - #${item.visit_ID}`
            };
          }
          // Return the item unchanged if either patient_Name or visit_ID is missing
          return item;
        });

        // Update the shoppingCart in the payload with the new array
        const updatedPayload = {
          ...action.payload,
          shoppingCardSearchResponse: updatedShoppingCart
        };

        return {
          ...state,
          isPurchaseFetched: false,
          isSuccess: true,
          purchaseList: updatedPayload
        };
      })
      .addCase(fetchPurchaseList.rejected, (state, action) => {
        return {
          ...state,
          isPurchaseFetched: false,
          isError: true,
          errorMessage: action.error.message,
          purchaseList: []
        };
      })
      .addCase(fetchPurchseOrderbyIDs.pending, (state) => {
        return {
          ...state,
          isPurchaseOrderFetched: true
        };
      })
      .addCase(fetchPurchseOrderbyIDs.fulfilled, (state, action) => {
        return {
          ...state,
          isPurchaseOrderFetched: false,
          isSuccess: true,
          purchaseOrdersData: action.payload
        };
      })
      .addCase(fetchPurchseOrderbyIDs.rejected, (state, action) => {
        return {
          ...state,
          isPurchaseOrderFetched: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchPaymentMethod.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchPaymentMethod.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          paymentMethods: action.payload.paymentType
        };
      })
      .addCase(fetchPaymentMethod.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchShipMethod.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchShipMethod.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          shipMethods: action.payload.shippingTypes
        };
      })
      .addCase(fetchShipMethod.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updatePurchaseOrder.pending, (state) => {
        return {
          ...state,
          isError: false,
          isPending: true
        };
      })
      .addCase(updatePurchaseOrder.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: action?.payload?.result?.is_Error || false,
          updatePurchaseOrderData: action.payload
        };
      })
      .addCase(updatePurchaseOrder.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updateCartQty.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(updateCartQty.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          updateShoppingCartQtyData: action.payload
        };
      })
      .addCase(updateCartQty.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchPurchaseOrderDetail.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchPurchaseOrderDetail.fulfilled, (state, action) => {
        const purchaseOrderItemDetails = action?.payload?.purchaseOrderItemDetails || [];
        const purchaseOrder = action?.payload?.purchaseOrderDetail || [];

        const enhancedPurchaseOrderItemDetails = purchaseOrderItemDetails?.map((item) => {
          if (item?.patient_Name && item?.visit_Id) {
            return {
              ...item,
              patientVisitInfo: `${item?.patient_Name} - #${item?.visit_Id}`,
              total_cost: purchaseOrder[0]?.total_cost,
              is_Approver_Shipping_Threshold: purchaseOrder[0]?.is_Approver_Shipping_Threshold
            };
          }
          return {
            ...item,
            total_cost: purchaseOrder[0]?.total_cost,
            is_Approver_Shipping_Threshold: purchaseOrder[0]?.is_Approver_Shipping_Threshold
          };
        });

        const updatedItemPayload = {
          ...action.payload,
          purchaseOrderItemDetails: enhancedPurchaseOrderItemDetails
        };

        return {
          ...state,
          isPending: false,
          purchaseOrderDetail: updatedItemPayload
        };
      })
      .addCase(fetchPurchaseOrderDetail.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(DraftPurchaseOrderDelete.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isPurchaseOrderDeleted: false
        };
      })

      .addCase(DraftPurchaseOrderDelete.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          deletePurchaseOrderData: action.payload,
          isPurchaseOrderDeleted: true
        };
      })

      .addCase(DraftPurchaseOrderDelete.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          isPurchaseOrderDeleted: false
        };
      })

      .addCase(shoppingCartItemsDelete.pending, (state) => {
        return {
          ...state,
          isShoppingCartItemDeletePending: true,
          isShoppingCartItemDeleted: false,
          isPending: true
        };
      })
      .addCase(shoppingCartItemsDelete.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isShoppingCartItemDeletePending: false,
          isShoppingCartItemDeleted: true,
          deletePurchaseOrderData: action.payload
        };
      })
      .addCase(shoppingCartItemsDelete.rejected, (state, action) => {
        return {
          ...state,
          isShoppingCartItemDeletePending: true,
          isShoppingCartItemDeleted: false,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(approveAndTransmit.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(approveAndTransmit.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(approveAndTransmit.rejected, (state, action) => {
        return {
          ...state,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchPurchaseOrderStatus.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })

      .addCase(fetchPurchaseOrderStatus.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          purchaseOrderStatusData: action.payload
        };
      })

      .addCase(fetchPurchaseOrderStatus.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchCartLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchCartLocation.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          cartLocation: uniqBy(action.payload, 'location_Id')
        };
      })
      .addCase(fetchCartLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(addItemToCart.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(addItemToCart.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchPurchaseOrderTransactionLogs.pending, (state) => {
        return {
          ...state,
          isPendingTransactionLogs: true
        };
      })
      .addCase(fetchPurchaseOrderTransactionLogs.fulfilled, (state, action) => {
        return {
          ...state,
          isPendingTransactionLogs: false,
          purchaseOrderTransactionLogs: action.payload
        };
      })
      .addCase(fetchPurchaseOrderTransactionLogs.rejected, (state, action) => {
        return {
          ...state,
          isPendingTransactionLogs: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updateReceivePOQuantity.pending, (state) => {
        return {
          ...state,
          isPendingUpdateReceiveItems: true
        };
      })
      .addCase(updateReceivePOQuantity.fulfilled, (state, action) => {
        return {
          ...state,
          isPendingUpdateReceiveItems: false,
          updateReceivePOData: action.payload
        };
      })
      .addCase(updateReceivePOQuantity.rejected, (state, action) => {
        return {
          ...state,
          isPendingUpdateReceiveItems: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchItemListForPurchaseItem.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchItemListForPurchaseItem.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          vendorsList: action.payload
        };
      })
      .addCase(fetchItemListForPurchaseItem.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchShoppingCartLastRunCronJobDetail.pending, (state) => {
        return {
          ...state,
          isCronPending: true
        };
      })
      .addCase(fetchShoppingCartLastRunCronJobDetail.fulfilled, (state, action) => {
        return {
          ...state,
          isCronPending: false,
          lastRunCronDetails: action.payload
        };
      })
      .addCase(fetchShoppingCartLastRunCronJobDetail.rejected, (state, action) => {
        return {
          ...state,
          isCronPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetDownloadPO.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetDownloadPO.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          downloadPOData: action.payload
        };
      })
      .addCase(GetDownloadPO.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = purchaseSlice;

export const {
  setPurchaseListAPIPayload,
  setPurchaseList,
  setVendorList,
  setViewPOByStatus,
  setReceivePOData,
  setSelectedPOStatus,
  setSelectedPurchaseLocation,
  setActiveAccrodian,
  setPurchaseOrderDetail,
  setIsCreateView,
  setIsPurchaseCountFetched,
  setFromPurchaseDashboard
} = actions;

export default reducer;
